import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ClientFormComponent } from "./components/client-form/client-form.component";

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: "",
          component: ClientFormComponent,
          pathMatch: "full",
        },
        {
          path: "forms/:id",
          component: ClientFormComponent,
        },
        {
          path: ":id",
          component: ClientFormComponent,
        },
        {
          path: "**",
          component: ClientFormComponent
        },
      ],
      { relativeLinkResolution: "legacy" }
    ),
  ],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
