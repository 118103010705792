import { sharedEnvironment } from "@fourcomply-dashboard/environments";

export const environment = {
  production: true,
  auth: {
    CLIENT_ID: sharedEnvironment.eu.auth.CLIENT_ID,
    CLIENT_DOMAIN: sharedEnvironment.eu.auth.CLIENT_DOMAIN,
    AUDIENCE: sharedEnvironment.eu.auth.AUDIENCE,
    REDIRECT: sharedEnvironment.eu.auth.admin.REDIRECT,
    LOGOUT_URL: sharedEnvironment.eu.auth.admin.LOGOUT_URL
  },
  api: {
    API_ENDPOINT: sharedEnvironment.eu.api.API_ENDPOINT
  }
};
