<div class="sorry-wrap" *ngIf="!formObj">
  <h1>{{ msg }}</h1>
</div>

<div class="container" *ngIf="formObj">
  <div class="column col-5 col-mx-auto">
    <div class="info-success" *ngIf="formSubmitMsg">
      <p>{{ formSubmitMsg }}</p>
    </div>
    <app-generic-card *ngIf="!formSubmitMsg">
      <div *ngIf="clientLogoUrl" class="image-wrap">
        <img src="{{ clientLogoUrl }}" />
      </div>
      <h3 class="section-title">{{ formObj.title }}</h3>
      <div>
        <span class="section-subHeader">{{ formObj.subHeader }}</span>
      </div>
      <div>
        <span class="section-description">{{ formObj.description }}</span>
      </div>
      <br />
      <app-form
        #former
        [fields]="fields"
        [model]="model"
        [callback]="submitForm"
        *ngIf="!viewOnly"
        [loadingDone]="!formSubmitted"
      ></app-form>
      <div class="read-only-form" *ngIf="viewOnly">
        <div class="row" *ngFor="let field of fields">
          <div class="title">{{ field.templateOptions.label }}</div>
          <div>{{ this.model[field.key] }}</div>
        </div>
      </div>
      <div style="color: red;margin-top: 15px;" *ngIf="formError">
        <b>{{ formError }}</b>
      </div>
    </app-generic-card>
  </div>
</div>
