import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormlyMaterialModule } from "@ngx-formly/material";
import { AppComponent } from "./app.component";
import { ClientFormComponent } from "./components/client-form/client-form.component";
import { AppRoutingModule } from "./app-routing.module";
import {
  APIService,
  FormComponent,
  ModalComponent,
  DatatableComponent,
  GenericCardComponent,
  PaginationComponent,
  NotificationService,
  ActionBarComponent,
} from "@fourcomply-dashboard/shared";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { FormlyModule } from "@ngx-formly/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json?v=2.5.0");
}

@NgModule({
  declarations: [
    AppComponent,
    ClientFormComponent,
    FormComponent,
    ModalComponent,
    DatatableComponent,
    ActionBarComponent,
    PaginationComponent,
    GenericCardComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxDatatableModule,
    HttpClientModule,
    FormlyModule.forRoot({
      validationMessages: [{ name: "required", message: "Required" }],
    }),
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [APIService],
  bootstrap: [AppComponent],
})
export class AppModule {}
