import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  APIService,
  ClientForm,
  ComplianceInput,
  FormComponent,
} from "@fourcomply-dashboard/shared";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "fourcomply-dashboard-client-form",
  templateUrl: "./client-form.component.html",
  styleUrls: ["./client-form.component.scss"],
})
export class    ClientFormComponent implements OnInit {
  @ViewChild("former", { static: true })
  clientForm: FormComponent;
  msg: string;
  formSubmitMsg: string;
  fields: any[] = [];
  formSubmitted = false;
  requestId: string;
  nextState: string;
  token: string;
  description: string;
  viewOnly: boolean = false;
  model: any = {};
  formObj: ClientForm;
  formError: any;
  clientLogoUrl: string;
  currentConsent: ComplianceInput;
  constructor(
    public api: APIService,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {}

  ngOnInit() {
    this.submitForm = this.submitForm.bind(this);

    const formId = this.route.snapshot.params["id"];
    console.log("ClientFormController" + formId);
    if (formId === "norightsdata") {
      this.setRightErrorMessage();
    } else if (formId === "updatedata") {
      this.setUpUpdateForm();
    } else if (formId === "accessdata") {
      this.setUpAccessForm();
    } else if (formId === "changeconsent") {
      this.setUpChangeConsentForm();
    } else if (formId) {
      this.fetchForm(formId);
    } else {
      this.setErrorMessage();
    }
  }

  getLogo(id) {
    this.api.clients.getLogo(id).subscribe((a: any) => {
      this.clientLogoUrl = a.url;
    });
  }

  getFavicon(id) {
    this.api.clients.getFavicon(id).subscribe((a: any) => {
      console.log(a);
      this._document.getElementById("appIcon").setAttribute("href", a.url);
    });
  }

  setUpAccessForm() {
    // List url params
    this.fields = [];
    this.viewOnly = true;
    let clientId = this.route.snapshot.queryParams["tenant_id"];
    this.requestId = this.route.snapshot.queryParams["request_id"];
    this.token = this.route.snapshot.queryParams["token"];
    this.getLogo(clientId);
    this.getFavicon(clientId);
    // List url params
    this.fields = [];
    console.log('Access form'+ this.token);
    // Get Data
    this.description=    "Please provide your email address and country.  We will send you an email shortly to confirm your identity.";
    this.api.rightRequests
      .updateRightRequestWithToken(
        {},
        clientId,
        this.requestId,
        "GetData",
        this.token
      )
      .subscribe((a) => {
        console.log(a);
        Object.keys(a).forEach((key: any) => {
         
         /* let parsedValue = JSON.parse(a[key]);
          this.model[key] = parsedValue.value;
          this.fields.push({
            type: "input",
            name: parsedValue.label,
            key: key,
            templateOptions: {
              name: parsedValue.label,
              label: parsedValue.label || key,
            },
          });*/
          this.model[key] = a[key];
          this.fields.push({
            type: "input",
            name: key,
            key: key,
            templateOptions: {
              name: key,
              label: key,
            },
          });
        });
        this.description="";
      });

    this.nextState = this.route.snapshot.queryParams["next_state"];
    this.token = this.route.snapshot.queryParams["token"];
    this.formObj = {
      id: "test",
      submitAction: "PUT /rightsrequests",
      title: "Right to Access Your Information",
      subHeader: "Exercise your right to access your personal information",
      description: "",
      tenantId: this.route.snapshot.queryParams["tenant_id"],
      type: "AccessForm",
      fields: this.fields,
    };
  }

  setUpUpdateForm() {
    let clientId = this.route.snapshot.queryParams["tenant_id"];
    this.requestId = this.route.snapshot.queryParams["request_id"];
    this.nextState = this.route.snapshot.queryParams["next_state"];
    this.token = this.route.snapshot.queryParams["token"];
    this.token = this.route.snapshot.queryParams["token"];
    this.getLogo(clientId);
    this.getFavicon(clientId);
    // List url params
    this.fields = [];
    // Get Data
    this.api.rightRequests
      .updateRightRequestWithToken(
        {},
        clientId,
        this.requestId,
        "GetData",
        this.token
      )
      .subscribe((a) => {
        Object.keys(a).forEach((key: any) => {
         /* let parsedValue = JSON.parse(a[key]);
          this.model[key] = parsedValue.value;
          this.fields.push({
            type: "input",
            name: key,
            key: key,
            templateOptions: {
              name: key,
              label: key,
            },
          });*/

          this.model[key] = a[key];
          this.fields.push({
            type: "input",
            name: key,
            key: key,
            templateOptions: {
              name: key,
              label: key,
            },
          });
        });
        this.formObj = {
          id: "test",
          submitAction: "PUT /rightsrequests",
          title: "Right to Update Your Information",
          subHeader: "Exercise your right to update your personal information",
          description:
            "",
          tenantId: this.route.snapshot.queryParams["tenant_id"],
          type: "UpdateForm",
          fields: this.fields,
        };
      });
  }

  setUpChangeConsentForm() {
    let clientId = this.route.snapshot.queryParams["tenant_id"];
    this.requestId = this.route.snapshot.queryParams["request_id"];
    this.nextState = this.route.snapshot.queryParams["next_state"];
    this.token = this.route.snapshot.queryParams["token"];
    this.getLogo(clientId);
    this.getFavicon(clientId);
    // Allow to change consent
    this.fields = [
      {
        type: "radio",
        name: "Consent",
        key: "consent_code",
        description: "Do you consent to communication from this company?",
        templateOptions: {
          options: [
            {
              value: "yes",
              label: "Yes",
            },
            {
              value: "no",
              label: "No",
            },
          ],
        },
      },
    ];
    // Get Data
    this.api.rightRequests
      .updateRightRequestWithToken(
        {},
        clientId,
        this.requestId,
        "GetComplianceInputData",
        this.token
      )
      .subscribe((a) => {
        if (a == null) {
          this.msg =
            "Sorry, you don't currently have any compliance input settings.";
        } else {
          this.currentConsent = a;
          this.formObj = {
            id: "test",
            submitAction: "POST /citizens/complianceinput",
            title: "Change your Consent Information",
            subHeader: "Your current consent value is: " + a.consent_code,
            description: "Please update your choices.",
            tenantId: this.route.snapshot.queryParams["tenant_id"],
            type: "ChangeConsent",
            fields: this.fields,
          };
        }
      });
  }

  setRightErrorMessage() {
    this.msg = "Sorry, we couldn't find any data for your right request.";
  }

  setErrorMessage() {
    this.msg = "Sorry, this URL is incorrect. Please check and try again.";
  }

  fetchForm(formId) {
    this.api.clientForms.get(formId).subscribe((data) => {
      if (!data) {
        this.setErrorMessage();
      }
      this.formObj = data;
      this.getLogo(data.tenantId);
      this.getFavicon(data.tenantId);
      this.buildForm();
    });
  }

  buildForm() {
    this.formObj.fields.forEach((elem) => {
      const tempField: FormlyFieldConfig = {
        key: elem.identifier,
        templateOptions: {
          label: elem.name,
        },
      };
      let toPush = true;
      switch (elem.type) {
        case "longtext":
          tempField.type = "input";
          break;
        case "text":
          tempField.type = "input";
          break;
        case "select":
          tempField.type = "select";
          tempField.templateOptions.options = elem.options.map(
            (e: any, index) => {
              e.value = e.boolean_value || e.value;
              return e;
            }
          );
          break;
        case "geoSelect":
          tempField.type = "select";
          tempField.key = elem.identifier;
          tempField.templateOptions.label = "Country";
          tempField.templateOptions.options = elem.options
            .map((e: any, index) => {
              e.value = e.boolean_value || e.value;
              if (e.sub_options) {
                console.log(e);
              }
              return e;
            })
            .filter((a) => a != null);
          console.log(tempField.templateOptions.options);
          let a = (field, $event1) => {
            this.model.geo_sub = $event1.value;
            // get state options from country
            tempField.templateOptions.options.forEach((country: any) => {
              if (country.value == $event1.value) {
                if (country.sub_options && country.sub_options.length) {
                  const tempField2: FormlyFieldConfig = {
                    key: elem.identifier,
                    type: "select",
                    templateOptions: {
                      change: (field, $event2) => {
                        this.model[elem.identifier] = $event2.value;
                        this.model.geo_type_header = "state";
                      },
                      label: "State",
                      options: country.sub_options.map((e: any, index) => {
                        e.value = e.boolean_value || e.value;
                        return e;
                      }),
                    },
                  };
                  this.fields.forEach((f, index) => {
                    if (f.templateOptions.label === "Country") {
                      f.key = "geo_sub";
                    }
                  });
                  this.fields = [...this.fields, tempField2];
                } else {
                  this.fields.forEach((f, index) => {
                    if (f.templateOptions.label === "State") {
                      this.fields.splice(index, 1);
                    }
                  });
                  this.fields.forEach((f, index) => {
                    if (f.key === "geo_sub") {
                      f.key = elem.identifier;
                    }
                  });
                  this.model[elem.identifier] = $event1.value;
                  this.model.geo_type_header = "country";
                }
              }
            });
          };
          tempField.templateOptions.change = a.bind(this);
          // Add options
          break;
        case "radio":
          tempField.type = "radio";
          tempField.templateOptions.options = elem.options.map(
            (e: any, index) => {
              e.value = e.boolean_value || e.value;
              return e;
            }
          );
          break;
        case "hidden":
          toPush = false;
          this.model[elem.identifier] = elem.description;
          break;
        case "hidden_dependency":
          toPush = false;
          break;
        default:
          tempField.type = "input";
          break;
      }

      if (elem.validation && elem.validation.indexOf("required") !== -1) {
        tempField.templateOptions.required = true;
      }

      if (toPush) {
        this.fields.push(tempField);
      }
    });
  }

  fillHiddenFields() {
    this.formObj.fields.forEach((elem) => {
      switch (elem.type) {
        case "hidden_dependency":
          // Check to see which option we should choose
          elem.options.forEach((o) => {
            console.log(elem, o, this.model);
            if (this.model[elem.description] == o.value) {
              this.model[elem.identifier] = o.label;
            }
          });
          break;
      }
    });
  }

  cleanNull(model) {
    let toReturn = {};
    Object.keys(model).forEach((key) => {
      if (key) {
        toReturn[key] = model[key];
      }
    });
    return toReturn;
  }

  parseError(err: HttpErrorResponse) {
    switch (err.status) {
      case 404:
        return "Request is missing method on API end. Please try again or contact support.";
      case 500:
        return err.error.ErrorMessage;
      case 403:
        return "Your User Level is not Authorized to do this";
      default:
        console.log(err);
        if (err.error && typeof err.error === "string") {
          return err.error;
        } else if (
          err.error &&
          err.error.errors &&
          Object.keys(err.error.errors) &&
          Object.keys(err.error.errors).length
        ) {
          // specific validation errors
          const err2 = err.error.errors[Object.keys(err.error.errors)[0]];
          if (err2 && err2.length > 1) {
            return err2[0];
          } else if (err2) {
            return err2[0];
          }
        } else {
          return err.statusText;
        }
        break;
    }
  }

  submitForm() {
    this.formSubmitted = true;
    // will improve this function while dealing with real submission
    const actionParts = this.formObj.submitAction.split(" ");
    const actionType = actionParts[0];
    const actionRoute = actionParts[1];
    this.fillHiddenFields();
    if (actionType === "POST" && actionRoute === "/citizens/complianceinput") {
      const a = this.cleanNull(this.model);
      // Get Override field
      if (this.currentConsent) {
        this.currentConsent.activity_date = new Date();
      }
      this.api.complianceInputs
        .createConsentIncognito(
          a,
          this.formObj.tenantId,
          this.model.geo_type_header,
          this.currentConsent
        )
        .subscribe(
          (data) => {
            this.formSubmitMsg =
              "Thank you very much for submitting this form.";
          },
          (err) => {
            this.formSubmitted = false;
            console.log(err);
            this.formError = this.parseError(err);
          }
        );
    } else if (actionType === "PUT" && actionRoute === "/rightsrequests") {
      const a = this.model;
      this.api.rightRequests
        .updateRightRequestWithToken(
          a,
          this.formObj.tenantId,
          this.requestId,
          this.nextState,
          this.token
        )
        .subscribe(
          (data) => {
            this.formSubmitMsg =
              "Thank you. We received your rights request. Please check your email for a confirmation message and click on the link to verify your identity and continue the process.";
          },
          (err) => {
            this.formSubmitted = false;
            this.formError = this.parseError(err);
          }
        );
    } else if (actionType === "POST" && actionRoute === "/rightsrequests") {
      const a = this.model;
      this.api.rightRequests
        .createRightsRequestIncognito(
          a,
          this.formObj.tenantId,
          this.model.geo_type_header
        )
        .subscribe(
          (data) => {
            this.formSubmitMsg =
              "Thank you. We received your rights request. Please check your email for a confirmation message and click on the link to verify your identity and continue the process.";
          },
          (err) => {
            this.formSubmitted = false;
            this.formError = this.parseError(err);
          }
        );
    }
  }
}
